import React, { useState } from 'react'
import { Navbar, Nav, Button, Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { DashboardIcon, LogoutIcon, SettingIcon, TeamsIcon, UsersIcon, EmailIcon } from '../../../assets/icons'
import useGameStore from '../../../store/GameStore'
import useUserStore from '../../../store/UserStore'
import socketEvents from '../../../utils/packet'
import LogoutComponent from '../../Login/LogoutComponent/LogoutComponent'
import './Sidebar.scss'

const Sidebar = props => {
  const [logoutVisible, setLogoutVisible] = useState(false)
  const { socket } = useGameStore()
  const { setWalletAddress, setUser, setUserId, setToken } = useUserStore()

  const routes = [
    {
      name: 'Dashboard',
      route: '/admin/dashboard',
      activeRoutes: ['/admin/dashboard'],
      activeIcon: <DashboardIcon fill="#0469ff" />,
      inactiveIcon: <DashboardIcon fill="#838383" />
    },
    {
      name: 'Users',
      route: '/admin/users',
      activeRoutes: ['/admin/users', '/admin/users/detail'],
      activeIcon: <UsersIcon fill="#0469ff" />,
      inactiveIcon: <UsersIcon fill="#838383" />
    },
    {
      name: 'Opening DB',
      route: '/admin/opening-db',
      activeRoutes: ['/admin/opening-db'],
      activeIcon: <UsersIcon fill="#0469ff" />,
      inactiveIcon: <UsersIcon fill="#838383" />
    },
    {
      name: 'Teams',
      route: '/admin/teams',
      activeRoutes: ['/admin/teams'],
      activeIcon: <TeamsIcon fill="#0469ff" />,
      inactiveIcon: <TeamsIcon fill="#838383" />
    },
    {
      name: 'Marketplace',
      route: '/admin/marketplace',
      activeRoutes: ['/admin/marketplace'],
      activeIcon: <TeamsIcon fill="#0469ff" />,
      inactiveIcon: <TeamsIcon fill="#838383" />
    },
    {
      name: 'Chess APIs',
      route: '/admin/chess-apis',
      activeRoutes: ['/admin/chess-apis'],
      activeIcon: <TeamsIcon fill="#0469ff" />,
      inactiveIcon: <TeamsIcon fill="#838383" />
    },
    {
      name: 'Admin Settings',
      route: '/admin/settings',
      activeRoutes: ['/admin/settings'],
      activeIcon: <SettingIcon fill="#0469ff" />,
      inactiveIcon: <SettingIcon fill="#838383" />
    },
    {
      name: 'Whitelist Email',
      route: '/admin/whitelist-email',
      activeRoutes: ['/admin/whitelist-email'],
      activeIcon: <EmailIcon fill="#0469ff" />,
      inactiveIcon: <EmailIcon fill="#838383" />
    }
  ]
  const navigate = useNavigate()
  const toggleLogout = async () => {
    setLogoutVisible(state => !state)
  }
  const onClickDisconnectUser = async () => {
    setUser(null)
    setWalletAddress(null)
    setLogoutVisible(false)
    setUserId(null)
    setToken(null)
    socket.emit(socketEvents.DISCONNECT)
    socket.disconnect()
    localStorage.clear()
    navigate('/')
  }
  return (
    <>
      <Navbar className="col-md-12 d-flex flex-column justify-content-between h-100">
        <Nav
          className="col-md-12 d-flex flex-column"
          activeKey="/dashboard"
          onSelect={selectedKey => alert(`selected ${selectedKey}`)}
        >
          <div className="sidebar-sticky"></div>
          {routes.map((ele, index) => (
            <Nav.Item key={index} className="col-md-12">
              <Nav.Link
                key={ele.name}
                onClick={e => {
                  e.preventDefault()
                  navigate(ele.route)
                }}
                className="menu-admin-link navbar-items"
              >
                {ele.activeRoutes.indexOf(window?.location?.pathname) !== -1
                  ? ele.activeIcon
                  : ele.inactiveIcon}
                <span
                  className={
                    ele.activeRoutes.indexOf(window?.location?.pathname) !== -1
                      ? 'sidebar-item-selected'
                      : 'sidebar-item'
                  }
                >
                  {ele.name}
                </span>
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
        <div className="d-flex form-container flex-column w-100">
          <Button
            className="back-site-btn"
            onClick={() => {
              navigate('/')
            }}
          >
            Go to the site
          </Button>
          <Button className="logout-admin-btn" onClick={toggleLogout}>
            <LogoutIcon style={{ marginRight: 8 }} />
            Log out
          </Button>
        </div>
      </Navbar>
      <Modal centered show={logoutVisible} onHide={toggleLogout}>
        <LogoutComponent
          onLogout={onClickDisconnectUser}
          onCancel={toggleLogout}
          lightTheme={true}
        />
      </Modal>
    </>
  )
}
export default Sidebar
