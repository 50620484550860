import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, InputGroup, Modal, Row, Table } from 'react-bootstrap'
import axiosInstance from '../../network/axiosInstance'
import { EmailIcon } from '../../assets/icons'
import { validateEmail } from '../../libraries/common'
import { toastErrorMixin } from '../../utils/interact'
const WhiteListEmail = (props) => {
  const [userData, setUserData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [form, setForm] = useState({})
  const [errors, setErrors] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const [isEditMode, setEditMode] = useState(false)

  const tableHead = [
    { id: 'email', label: 'Email' },
    { id: 'edit', label: '' },
    { id: 'remove', label: '' }
  ]

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value
    })
    // Check and see if errors exist, and remove them from the error object:
    if (errors[field]) {
      setErrors({
        ...errors,
        [field]: null
      })
    }
  }

  const findFormErrors = () => {
    const { email } = form
    const newErrors = {}
    if (!email || email === '') newErrors.email = 'Email cannot be blank!'
    else if (!validateEmail(email)) newErrors.email = 'Email should be valid!'
    return newErrors
  }

  const handleSubmit = async () => {
    const newErrors = findFormErrors()
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)
    } else {
      setSubmitting(true)
      try {
        const params = form
        const url = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/email-whitelist/addWhitelistEmail'
        const res = await axiosInstance.post(url, params)
        if (res && res.data && res.status === 200) {
          setModalVisible(false)
        } else {
          toastErrorMixin.fire({
            title: res.data.message
          })
        }
      } catch (err) {
        console.log('error', err)
        toastErrorMixin.fire({
          title: 'Error logging in.'
        })
      } finally {
        getUserEmails()
        setSubmitting(false)
      }
    }
  }

  const onEditPress = (ele) => {
    setForm({
      ...form,
      email: ele.email,
      id: ele._id
    })
    setEditMode(true)
    toogleModal()
  }

  const handleEdit = async () => {
    setEditMode(false)
    const newErrors = findFormErrors()
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)
    } else {
      setSubmitting(true)
      try {
        const params = form
        const url = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/email-whitelist/updateWhitelistEmail'
        const res = await axiosInstance.post(url, params)
        if (res && res.data && res.status === 200) {
          setModalVisible(false)
          setEditMode(false)
        } else {
          setEditMode(false)
          toastErrorMixin.fire({
            title: res.data.message
          })
        }
      } catch (err) {
        setEditMode(false)
        console.log('error', err)
        toastErrorMixin.fire({
          title: 'Error logging in.'
        })
      } finally {
        getUserEmails()

        setEditMode(false)
        setSubmitting(false)
      }
    }
  }

  const handleRemove = async (email) => {
    try {
      const params = { email }
      const url = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/email-whitelist/deleteWhitelistEmail'
      const res = await axiosInstance.post(url, params)
      if (res?.status === 200) {
        getUserEmails()
      }
    } catch (err) {
      console.log('err', err)
      toastErrorMixin.fire({
        title: 'Error destroying user.'
      })
    }
  }

  const toogleModal = () => {
    if (modalVisible && isEditMode) {
      setForm({
        ...form,
        email: '',
        id: ''
      })
    }
    setModalVisible(state => !state)
  }

  const getUserEmails = async () => {
    try {
      setIsLoading(true)
      const whitelistMailUrl =
        process.env.REACT_APP_BACKEND_ENDPOINT +
        'api/v1/email-whitelist/fetchWhitelistEmail'
      const res = await axiosInstance.post(whitelistMailUrl)
      if (res?.status === 200) {
        setUserData(res?.data)
      }
      setIsLoading(false)
    } catch (err) {
      console.log('err', err)
    }
  }

  const handleFormSubmitting = (e) => {
    e.preventDefault()
    if (isEditMode) {
      handleEdit()
    } else {
      handleSubmit()
    }
  }

  useEffect(() => {
    getUserEmails()
  }, [])
  return (
    <Container className="admin-user-container">
      <div className="title">
        Users Mail
        <div className="d-flex">
          <Button onClick={toogleModal} className="edit-btn mx-4">{'Add Email'}</Button>
        </div>
      </div>
      <div className="main-detail-container">
        <Row className="mt-4" style={{ height: '100%' }}>
          {isLoading && <div>Loading...</div>}
          {userData?.length
            ? (
            <Col xs={12}>
              <Table responsive bordered hover>
                <thead>
                  <tr>
                    <th className="table-cell">#</th>
                    {tableHead.map((ele, index) => (
                      <th key={index} className="table-cell">
                        {ele.label}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {userData.map((ele, index) => (
                    <tr key={index}>
                      <td className="table-cell">{index + 1}</td>
                      <td className="table-cell">{ele.email}</td>
                      <td className="table-cell">
                        <Button disabled={submitting} onClick={() => onEditPress(ele)} className="edit-btn">{'Edit'}</Button>
                      </td>
                      <td className="table-cell">
                        <Button disabled={submitting} onClick={() => handleRemove(ele.email)} className="remove-btn">{'Remove'}</Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
              )
            : null}
        </Row>
      </div>
      <Modal centered show={modalVisible} onHide={toogleModal} >
      <Row className="quick-match-area">
      <div className="register-container">
        <h1 className="title">{'ADD USER EMAIL'}</h1>
        <Form noValidate onSubmit={() => {}}>
          <Row className="mb-3">
            <Form.Group as={Col} md="12" controlId="validationCustom02" className="form-container">
              <InputGroup hasValidation={true} className={`${errors.email ? 'is-invalid' : ''}`}>
                <InputGroup.Text>
                  <EmailIcon height={20} width={20} fill="#737171" />
                </InputGroup.Text>
                <Form.Control
                  className="inputboxEmail"
                  required
                  type="email"
                  value={form.email}
                  placeholder="Email"
                  onChange={e => setField('email', e.target.value)}
                  isInvalid={!!errors.email}
                />
              </InputGroup>
              <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3 mt-5">
            <Col>
              <div className="button-parent">
                <Button disabled={submitting} onClick={handleFormSubmitting} type="submit" className="register-btn">
                {isEditMode ? 'UPDATE' : 'ADD'}
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col>
              <div className="button-parent">
                <Button
                  disabled={submitting}
                  className="register-btn"
                  onClick={toogleModal}
                >
                  CANCEL
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </Row>
      </Modal>
    </Container>
  )
}

export default WhiteListEmail
