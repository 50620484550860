import React, { useEffect, useCallback } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import useGameStore from './store/GameStore'
import useUserStore from './store/UserStore'
import useGlobalStore from './store/GlobalStore'

// import Play from './pages/Play'
// import Landing from './pages/Landing'
// import GameBoard from './pages/GameBoard'
import GameBoardAI from './pages/GameBoardAI'
// import Marketplace from './pages/Marketplace'
import Collection from './pages/Collection'
import Whitelist from './pages/Whitelist'
// import GamesHistory from './pages/GamesHistory'
// import GameHistoryDetails from './pages/GameHistoryDetails'

import socketEvents from './utils/packet'
import { toastMixin } from './utils/interact'

import 'bootstrap/dist/css/bootstrap.min.css'
import './style.css'
import UserLayout from './components/Layout/UserLayout'
import AdminLayout from './components/Layout/AdminLayout'
import Dashboard from './pages/Dashboard'
import Teams from './pages/Teams'
import Settings from './pages/Settings'
import AdminMarketplace from './pages/AdminMarketplace'
import Home from './pages/Home'
import AdminUsers from './pages/AdminUsers/AdminUsers'
import AdminUsersDetails from './pages/AdminUsersDetails/AdminUsersDetails'
import DailyPuzzle from './pages/DailyPuzzle'
import OpeningDb from './pages/OpeningDb'
import ChessApiAnalytics from './pages/ChessApiAnalytics'
import WhiteListEmail from './pages/WhiteListEmail'

const App = ({ socket }) => {
  const { setSocket, gameInfo } = useGameStore()
  const { setNumberOfActiveUsers } = useGlobalStore()
  const { user, fetchUser, fetchPWNBalance, walletAddress } = useUserStore()

  useEffect(() => {
    setSocket(socket)
  }, [])

  useEffect(() => {
    if (socket?.disconnect && user) {
      socket.auth = { userId: user?._id }
      socket.connect()
    }

    socket.on(socketEvents.SC_TokenReward, res => {
      if (res.gameInfo.game_id === gameInfo.game_id) {
        fetchUser(user._id)
        fetchPWNBalance(walletAddress)
      }
    })

    socket.on(socketEvents.SC_TokenReward_Error, res => {
      if (res.gameInfo.game_id === gameInfo.game_id) {
        toastMixin.fire({
          title: 'There is an error with distributing your reward!',
          icon: 'error'
        })
      }
    })

    socket.on(socketEvents.GET_NO_USERS, res => {
      setNumberOfActiveUsers(res.numberOfUsers)
    })

    return () => {
      socket.off(socketEvents.GET_NO_USERS)
      socket.off(socketEvents.SC_TokenReward_Error)
      socket.off(socketEvents.SC_TokenReward)
    }
  }, [user, gameInfo, walletAddress])

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => window.removeEventListener('beforeunload', alertUser)
  }, [])

  const alertUser = useCallback(() => {
    if (socket) {
      if (user) {
        socket.emit(socketEvents.DISCONNECT)
        socket.emit(socketEvents.LEAVEROOM)
      }
      socket.disconnect()
    }
  }, [socket, user])

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<UserLayout />}>
            <Route index element={<Home />} />
            <Route path="play" element={<GameBoardAI />} />
            <Route path="collection" element={<Collection />} />
            <Route path="whitelist" element={<Whitelist />} />
            {/* <Route path="" element={user ? <Navigate to="/room" replace /> : <Landing />} />
            <Route path="room" element={<Play />} />
            <Route path="play" element={<GameBoard />} />
            <Route path="marketplace" element={<Marketplace />} />
            <Route path="history" element={<GamesHistory />} />
            <Route path="history/:gameId" element={<GameHistoryDetails />} /> */}
          </Route>

          <Route path="/">
            <Route path="puzzle" element={<DailyPuzzle />} />
          </Route>
          <Route path="/admin" element={<AdminLayout />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="users" element={<AdminUsers />} />
            <Route path="opening-db" element={<OpeningDb />} />
            <Route path="users/detail" element={<AdminUsersDetails />} />
            <Route path="teams" element={<Teams />} />
            <Route path="marketplace" element={<AdminMarketplace />} />
            <Route path="chess-apis" element={<ChessApiAnalytics />} />
            <Route path="settings" element={<Settings />} />
            <Route path="whitelist-email" element={<WhiteListEmail />} />
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  )
}

export default App
