import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, InputGroup, Row, Table } from 'react-bootstrap'
import './AdminUsersDetails.scss'
import { useLocation } from 'react-router-dom'
import useAdminStore from '../../store/AdminStore'
import axiosInstance from '../../network/axiosInstance'
import { toastErrorMixin } from '../../utils/interact'

const AdminUsersDetails = props => {
  const { state } = useLocation()
  const [isEditMode, setEditMode] = useState(false)
  const [form, setForm] = useState({})
  const [errors, setErrors] = useState({})
  const { fetchUserData, fetchUserRank, fetchUserCollection } = useAdminStore()
  const [submitting, setSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [userData, setUserData] = useState({})

  const setField = (field, key, value) => {
    setForm({
      ...form,
      [field]: {
        ...form[field],
        [key]: value
      }
    })
    // Check and see if errors exist, and remove them from the error object:
    if (errors[field] && errors[field][key]) {
      setErrors({
        ...errors,
        [field]: {
          ...errors[field],
          [key]: null
        }
      })
    }
  }

  const toggleEdit = () => {
    setEditMode(state => !state)
  }

  const getUserData = async user => {
    try {
      setIsLoading(true)
      const [userDetail, userRank, userCollection] = await Promise.all([
        fetchUserData({ userId: user?._id }),
        fetchUserRank({ userId: user?._id }),
        fetchUserCollection({ userId: user?._id })
      ])
      setUserData({ userDetail, userRank, userCollection })
    } catch (err) {
      console.log('err', err)
    } finally {
      setIsLoading(false)
    }
  }
  const { userDetail, userRank, userCollection } = userData

  useEffect(() => {
    getUserData(state)
  }, [state])

  const findFormErrors = (data, key, newErrors) => {
    if (['marketplace', 'arena', 'role', 'isPreliminary', 'debug_mode'].includes(key)) return null
    if (typeof data === 'object') {
      const keys = Object.keys(data)
      if (keys.length) {
        keys.forEach(titleKey => {
          const err = findFormErrors(data[titleKey], titleKey, newErrors)
          console.log('err', err)
          if (err) {
            newErrors = {
              ...newErrors,
              [titleKey]: err
            }
          }
        })
      }
    } else {
      if (!data) return `${key.charAt(0).toUpperCase() + key.slice(1)} cannot be blank.`
      return null
    }
    if (newErrors && Object.keys(newErrors).length) return newErrors
    else return null
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const newErrors = {}
    const foundError = findFormErrors(form, 'error', newErrors)
    if (foundError && Object.keys(foundError).length) {
      setErrors(foundError)
    } else {
      try {
        setSubmitting(true)
        if (form.user) {
          try {
            const params = { ...form.user, userId: userDetail._id }
            const url = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/user/admin/updateUser'
            await axiosInstance.put(url, params)
          } catch (err) {
            toastErrorMixin.fire({
              title: 'Error updating user.'
            })
          }
        }
        if (form.rank) {
          try {
            const params = { ...form.rank, userId: userDetail._id }
            if (form.rank.rank) params.score = form.rank.rank
            const url = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/ranking/admin/updateRank'
            await axiosInstance.put(url, params)
          } catch (err) {
            toastErrorMixin.fire({
              title: 'Error updating user.'
            })
          }
        }
        if (form.collection && form.collection.marketplace) {
          try {
            const params = { marketplace: form.collection.marketplace, userId: userDetail._id }
            const url = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/collection/admin/addNew'
            await axiosInstance.put(url, params)
          } catch (err) {
            toastErrorMixin.fire({
              title: 'Error updating user.'
            })
          }
        }
      } catch (err) {
        console.log('err', err)
        toastErrorMixin.fire({
          title: 'Error updating data.'
        })
      } finally {
        getUserData(state)
        setSubmitting(false)
        toggleEdit()
      }
    }
  }

  const tableHead = [
    { id: 'avatar', label: 'Avatar' },
    { id: '_id', label: 'Marketplace id' },
    { id: 'name', label: 'Name' },
    { id: 'inUse', label: 'In Use' },
    { id: 'color', label: 'Color' },
    { id: 'class', label: 'Class' },
    { id: 'rarity', label: 'Rarity' },
    { id: 'powerName', label: 'AI Power' }
  ]

  const tableEditHead = [
    { id: '_id', label: 'Marketplace id' },
    { id: 'name', label: 'Name' },
    { id: 'inUse', label: 'In Use' },
    { id: 'rarity', label: 'Rarity' },
    { id: 'powerName', label: 'AI Power' },
    { id: 'delete', label: '' }
  ]

  const removeFromCollection = async (id, index) => {
    try {
      if (id) {
        const url = process.env.REACT_APP_BACKEND_ENDPOINT + 'api/v1/collection/admin/delete'
        await axiosInstance.delete(url, { params: { id } })
        const newUser = { ...userData }
        newUser.userCollection.data.splice(index, 1)
        setUserData(newUser)
      }
    } catch (err) {
      toastErrorMixin.fire({
        title: 'Error removing collection.'
      })
    }
  }
  return (
    <Container className="user-detail-container">
      <div className="title">
        User Detail
        <div className="d-flex">
          {isEditMode && (
            <Button disabled={submitting} className="cancel-btn" onClick={toggleEdit}>
              Cancel
            </Button>
          )}
          <Button
            disabled={submitting}
            className="edit-btn"
            onClick={isEditMode ? handleSubmit : toggleEdit}
          >
            {isEditMode ? 'Update' : 'Edit'}
          </Button>
        </div>
      </div>
      <div className="main-detail-container">
        {isLoading && <div>Loading...</div>}
        {!isLoading && userRank
          ? (
          <Form noValidate onSubmit={handleSubmit}>
            <Container>
              <div className="sub-title">Personal Detail</div>
              <Row className="mt-2">
                <Col md={2} xs={6} className="heading">
                  Username:
                </Col>
                {isEditMode
                  ? (
                  <Form.Group as={Col} md="8" xs={6}>
                    <InputGroup
                      hasValidation={true}
                      className={`${errors.user?.userName ? 'is-invalid' : ''}`}
                    >
                      <Form.Control
                        className="inputbox"
                        required
                        defaultValue={userDetail.userName}
                        onChange={e => setField('user', 'userName', e.target.value)}
                        isInvalid={!!errors.user?.userName}
                      />
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      {errors.user?.userName}
                    </Form.Control.Feedback>
                  </Form.Group>
                    )
                  : (
                  <Col md="8" xs={6} className="detail">
                    {userDetail.userName}
                  </Col>
                    )}
              </Row>
              <Row className="mt-2">
                <Col md={2} xs={6} className="heading">
                  Email:
                </Col>
                {isEditMode
                  ? (
                  <Form.Group as={Col} md="8" xs={6}>
                    <InputGroup
                      hasValidation={true}
                      className={`${errors.user?.email ? 'is-invalid' : ''}`}
                    >
                      <Form.Control
                        className="inputbox"
                        required
                        type="email"
                        defaultValue={userDetail.email}
                        onChange={e => setField('user', 'email', e.target.value)}
                        isInvalid={!!errors.user?.email}
                      />
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      {errors.user?.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                    )
                  : (
                  <Col md="8" xs={6} className="detail">
                    {userDetail.email}
                  </Col>
                    )}
              </Row>
              <Row className="mt-2">
                <Col md={2} xs={6} className="heading">
                  Wallet Id:
                </Col>
                {isEditMode
                  ? (
                  <Form.Group as={Col} md="8" xs={6}>
                    <InputGroup
                      hasValidation={true}
                      className={`${errors.user?.walletId ? 'is-invalid' : ''}`}
                    >
                      <Form.Control
                        className="inputbox"
                        required
                        defaultValue={userDetail.walletId}
                        onChange={e => setField('user', 'walletId', e.target.value)}
                        isInvalid={!!errors.user?.walletId}
                      />
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      {errors.user?.walletId}
                    </Form.Control.Feedback>
                  </Form.Group>
                    )
                  : (
                  <Col md="8" xs={6} className="detail">
                    {userDetail.walletId}
                  </Col>
                    )}
              </Row>
              <Row className="mt-2">
                <Col md={2} xs={6} className="heading">
                  Role:
                </Col>
                {isEditMode
                  ? (
                  <Form.Group as={Col} md="8" xs={6}>
                    <Form.Check
                      inline
                      label="Admin"
                      className="inputbox"
                      name="role"
                      type="radio"
                      defaultChecked={userDetail.role === 'admin'}
                      onChange={e => setField('user', 'role', 'admin')}
                      id="admin"
                    />
                    <Form.Check
                      inline
                      label="User"
                      className="inputbox"
                      name="role"
                      type="radio"
                      defaultChecked={userDetail.role === 'user'}
                      onChange={e => setField('user', 'role', 'user')}
                      id="user"
                    />
                  </Form.Group>
                    )
                  : (
                  <Col md="8" xs={6} className="detail text-capitalize">
                    {userDetail.role}
                  </Col>
                    )}
              </Row>
            </Container>
            <Container className="mt-4">
              <div className="sub-title">User Holdings</div>
              <Row className="mt-2">
                <Col md={2} xs={6} className="heading">
                  Gems:
                </Col>
                {isEditMode
                  ? (
                  <Form.Group as={Col} md="2" xs={6}>
                    <InputGroup
                      hasValidation={true}
                      className={`${errors.rank?.gems ? 'is-invalid' : ''}`}
                    >
                      <Form.Control
                        className="inputbox"
                        required
                        type="number"
                        defaultValue={userRank.gems}
                        onChange={e => setField('rank', 'gems', e.target.value)}
                        isInvalid={!!errors.rank?.gems}
                      />
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      {errors.rank?.gems}
                    </Form.Control.Feedback>
                  </Form.Group>
                    )
                  : (
                  <Col md="2" xs={6} className="detail">
                    {userRank.gems}
                  </Col>
                    )}
                <Col md={2} xs={6} className="heading vertical-divider">
                  ELO Rank:
                </Col>
                {isEditMode
                  ? (
                  <Form.Group as={Col} md="2" xs={6}>
                    <InputGroup
                      hasValidation={true}
                      className={`${errors.rank?.rank ? 'is-invalid' : ''}`}
                    >
                      <Form.Control
                        className="inputbox"
                        required
                        type="number"
                        defaultValue={userRank.rank}
                        onChange={e => setField('rank', 'rank', e.target.value)}
                        isInvalid={!!errors.rank?.rank}
                      />
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      {errors.rank?.rank}
                    </Form.Control.Feedback>
                  </Form.Group>
                    )
                  : (
                  <Col md="2" xs={6} className="detail">
                    {userRank.rank}
                  </Col>
                    )}
                <Col md={2} xs={6} className="heading vertical-divider">
                  Gold:
                </Col>
                {isEditMode
                  ? (
                  <Form.Group as={Col} md="2" xs={6}>
                    <InputGroup
                      hasValidation={true}
                      className={`${errors.rank?.gold ? 'is-invalid' : ''}`}
                    >
                      <Form.Control
                        className="inputbox"
                        required
                        type="number"
                        defaultValue={userRank.gold}
                        onChange={e => setField('rank', 'gold', e.target.value)}
                        isInvalid={!!errors.rank?.gold}
                      />
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      {errors.rank?.gold}
                    </Form.Control.Feedback>
                  </Form.Group>
                    )
                  : (
                  <Col md="2" xs={6} className="detail">
                    {userRank.gold}
                  </Col>
                    )}
              </Row>
              <Row className="mt-2">
                <Col md={2} xs={6} className="heading">
                  XP:
                </Col>
                {isEditMode
                  ? (
                  <Form.Group as={Col} md="2" xs={6}>
                    <InputGroup
                      hasValidation={true}
                      className={`${errors.rank?.xp ? 'is-invalid' : ''}`}
                    >
                      <Form.Control
                        className="inputbox"
                        required
                        type="number"
                        defaultValue={userRank.xp}
                        onChange={e => setField('rank', 'xp', e.target.value)}
                        isInvalid={!!errors.rank?.xp}
                      />
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">{errors.rank?.xp}</Form.Control.Feedback>
                  </Form.Group>
                    )
                  : (
                  <Col md="2" xs={6} className="detail">
                    {userRank.xp}
                  </Col>
                    )}
                {!isEditMode && (
                  <>
                    <Col md={2} xs={6} className="heading vertical-divider">
                      Level:
                    </Col>
                    <Col md="2" xs={6} className="detail">
                      {userRank.level}
                    </Col>
                  </>
                )}
                {!isEditMode && (
                  <>
                    <Col md={2} xs={6} className="heading vertical-divider">
                      Completed:
                    </Col>
                    <Col md="2" xs={6} className="detail">
                      {userRank.completed}
                    </Col>
                  </>
                )}
              </Row>
              <Row className="mt-2">
                <Col md={2} xs={6} className="heading">
                  Power:
                </Col>
                {isEditMode
                  ? (
                  <Form.Group as={Col} md="2" xs={6}>
                    <InputGroup
                      hasValidation={true}
                      className={`${errors.rank?.power ? 'is-invalid' : ''}`}
                    >
                      <Form.Control
                        className="inputbox"
                        required
                        type="number"
                        defaultValue={userRank.power}
                        onChange={e => setField('rank', 'power', e.target.value)}
                        isInvalid={!!errors.rank?.power}
                      />
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">{errors.rank?.power}</Form.Control.Feedback>
                  </Form.Group>
                    )
                  : (
                  <Col md="2" xs={6} className="detail">
                    {userRank.power}
                  </Col>
                    )}
                <Col md={2} xs={6} className="heading">
                  Debug Mode:
                </Col>
                <Form.Group as={Col} md="2" xs={6}>
                  <Form.Check
                    disabled={!isEditMode}
                    className="inputbox d-flex align-items-center"
                    name="debug_mode"
                    type="checkbox"
                    defaultChecked={userRank.debug_mode}
                    onChange={e => setField('rank', 'debug_mode', e.target.checked)}
                    id="debug_mode"
                  />
                </Form.Group>
                <Col md={2} xs={6} className="heading">
                  IsPreliminary:
                </Col>
                <Form.Group as={Col} md="2" xs={6}>
                  <Form.Check
                    disabled={!isEditMode}
                    className="inputbox d-flex align-items-center"
                    name="isPreliminary"
                    type="checkbox"
                    defaultChecked={userRank.isPreliminary}
                    onChange={e => setField('rank', 'isPreliminary', e.target.checked)}
                    id="isPreliminary"
                  />
                </Form.Group>
              </Row>
              {!isEditMode && (<Row className="mt-2 d-flex align-items-start">
                <Col md={2} xs={6} className="heading">
                  User Arena:
                </Col>
                  <Col md="10" xs={6} className="detail">
                    {userRank.arena?.arena}
                  </Col>
              </Row>)}
            </Container>
            <Container className="mt-4">
              <div className="sub-title">
                <div>User Collection Cards</div>
              </div>
              <Row className="mt-2">
                {isEditMode && (
                  <div className="d-flex mb-2">
                    <Col md={2} xs={6} className="heading">
                      Marketplace Id(s):
                    </Col>
                    <Form.Group as={Col} md="10" xs={6}>
                      <InputGroup>
                        <Form.Control
                          className="inputbox"
                          placeholder="Add multiple ids with comma seperated"
                          onChange={e => setField('collection', 'marketplace', e.target.value)}
                        />
                      </InputGroup>
                    </Form.Group>
                  </div>
                )}
                <Col xs={12}>
                  {isEditMode
                    ? (
                    <Table responsive striped bordered hover>
                      <thead>
                        <tr>
                          <th className="table-cell">#</th>
                          {tableEditHead.map((ele, index) => (
                            <th key={index} className="table-cell">
                              {ele.label}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {userCollection.data.map((collection, index) => {
                          return (
                            <tr key={index}>
                              <td className="table-cell">{index + 1}</td>
                              {tableEditHead.map((ele, ind) => {
                                if (ele.id === 'inUse') {
                                  return (
                                    <td key={ind} className="table-cell">
                                      <Form.Check
                                        inline
                                        disabled
                                        name="status"
                                        type="checkbox"
                                        className="m-auto"
                                        defaultChecked={collection[ele.id]}
                                      />
                                    </td>
                                  )
                                }

                                if (ele.id === 'delete') {
                                  return (
                                    <td key={ind} className="table-cell">
                                      <Button
                                        className="destroy-btn"
                                        onClick={() => removeFromCollection(collection._id, index)}
                                      >
                                        Remove
                                      </Button>
                                    </td>
                                  )
                                }
                                return (
                                  <td key={ind} className="table-cell">
                                    {collection.marketplace[ele.id]}
                                  </td>
                                )
                              })}
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                      )
                    : (
                    <Table responsive striped bordered hover>
                      <thead>
                        <tr>
                          <th className="table-cell">#</th>
                          {tableHead.map((ele, index) => (
                            <th key={index} className="table-cell">
                              {ele.label}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {userCollection.data.map((collection, index) => {
                          return (
                            <tr key={index}>
                              <td className="table-cell">{index + 1}</td>
                              {tableHead.map((ele, ind) => {
                                if (ele.id === 'avatar') {
                                  return (
                                    <td key={ind} className="avatar-container">
                                      <div className="image-container">
                                        <img
                                          className="image"
                                          src={collection.marketplace[ele.id]}
                                        ></img>
                                      </div>
                                    </td>
                                  )
                                }
                                if (ele.id === 'inUse') {
                                  return (
                                    <td key={ind} className="table-cell">
                                      <Form.Check
                                        inline
                                        disabled
                                        name="status"
                                        type="checkbox"
                                        className="m-auto"
                                        checked={collection[ele.id]}
                                      />
                                    </td>
                                  )
                                }

                                return (
                                  <td key={ind} className="table-cell">
                                    {collection.marketplace[ele.id]}
                                  </td>
                                )
                              })}
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                      )}
                </Col>
              </Row>
            </Container>
          </Form>
            )
          : null}
      </div>
    </Container>
  )
}
export default AdminUsersDetails
