import React from 'react'
import { Col, Row, Table } from 'react-bootstrap'
import { calculateAvgLast10Time, formatApiName } from './ChessApiAnalytics'

const tableHead = [
  { id: 'name', label: 'API NAME' },
  { id: 'last10Time', label: 'Avg execution time (last 10 calls)' },
  { id: 'maxTime', label: 'Max execution time (REM)' },
  { id: 'totalTime', label: 'Total number of calls (REM)' },
  { id: 'percentile95', label: '95th percentile of calls (REM)' },
  { id: 'cv', label: 'CV of calls (REM)' },
  { id: 'failCount', label: 'Failed number of calls (REM)' }
]

const renderRows = data => {
  if (data && Object.keys(data)?.length) {
    return Object.keys(data).map((key, idx) => (
      <tr key={key}>
        <td className="table-cell">{idx + 1}</td>
        {tableHead.map((ele, index) => {
          if (ele.id === 'name') {
            return (
              <td key={index + idx} className="table-cell">
                {formatApiName(key)}
              </td>
            )
          } else if (ele.id === 'last10Time') {
            return (
              <td key={index + idx} className="table-cell">
                {calculateAvgLast10Time(data[key]?.last10Time)}
              </td>
            )
          } else if (ele.id === 'cv') {
            return (
              <td key={index + idx} className="table-cell">
                {data[key]?.cv?.toFixed(4)}
              </td>
            )
          }
          return (
            <td key={index + idx} className="table-cell">
              {data[key][ele.id]}
            </td>
          )
        })}
      </tr>
    ))
  }
  return null
}

const ChessApiTable = ({ data }) => {
  return (
    <div className="main-detail-container">
      <Row>
        <Col xs={12}>
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th className="table-cell">#</th>
                {tableHead.map((ele, index) => (
                  <th key={index} className="table-cell">
                    {ele.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>{renderRows(data)}</tbody>
          </Table>
        </Col>
      </Row>
    </div>
  )
}

export default ChessApiTable
